var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',[_c('v-sheet',{staticClass:"h-auto"},[_c('div',{staticClass:"d-flex flex-column w-100"},[_c('div',{staticClass:"d-block w-100"},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-btn',{staticClass:"mr-4",attrs:{"outlined":"","color":"grey darken-2"},on:{"click":_vm.setToday}},[_vm._v(" "+_vm._s(_vm.$t("main.calendar.today"))+" ")]),_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.prev}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-chevron-left ")])],1),_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.next}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-chevron-right ")])],1),(_vm.$refs.calendar)?_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$refs.calendar.title)+" ")]):_vm._e(),_c('v-spacer'),_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":"","color":"grey darken-2"}},'v-btn',attrs,false),on),[_c('span',[_vm._v(_vm._s(_vm.typeToLabel[_vm.type]))]),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-menu-down ")])],1)]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){_vm.type = 'day'}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("main.calendar.day")))])],1),_c('v-list-item',{on:{"click":function($event){_vm.type = 'week'}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("main.calendar.week")))])],1),_c('v-list-item',{on:{"click":function($event){_vm.type = 'month'}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("main.calendar.month")))])],1),_c('v-list-item',{on:{"click":function($event){_vm.type = '4day'}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("main.calendar.4days")))])],1)],1)],1)],1)],1),_c('div',{staticClass:"d-block w-100"},[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"lg":"4","md":"5","sm":"12","sx":"12"}},[_c('div',{staticClass:"d-flex flex-column w-100"},[_c('SelectUserOptions',{staticClass:"pa-2",model:{value:(_vm.selectedUserIDs),callback:function ($$v) {_vm.selectedUserIDs=$$v},expression:"selectedUserIDs"}})],1)]),_c('v-col',[_c('v-btn',{staticClass:"primary mt-2",on:{"click":_vm.openPopupCreateRosterEvent}},[_vm._v(" "+_vm._s(_vm.$t("main.calendar.btnCreate"))+" ")])],1)],1)],1)])]),_c('v-sheet',[_c('CalendarContent',[_c('v-calendar',{ref:"calendar",attrs:{"events":_vm.events,"type":_vm.type},on:{"click:date":_vm.viewDay,"click:more":_vm.viewDay,"change":_vm.handleTimeRangeChanged,"mousedown:event":_vm.calendarEventClicked},scopedSlots:_vm._u([{key:"event",fn:function(ref){
var event = ref.event;
var timed = ref.timed;
return [_c('ItemEventBooking',[(timed)?_c('div',{staticClass:"timed"},[_c('p',{staticClass:"itemEvent__name"},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"#ffffff"}},[_vm._v(" mdi-account-box-outline ")]),_c('b',[_vm._v(_vm._s(event.name))])],1),_c('p',[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"#ffffff"}},[_vm._v(" mdi-clock-outline ")]),_c('b',[_vm._v(_vm._s(event.eventTimeString))])],1),(event.eventType == _vm.eventType.Roster)?_c('p',[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"#ffffff"}},[_vm._v(" mdi-account-box-outline ")]),_c('b',[_vm._v(_vm._s(event.userCreatedName))]),(event.attendees.length)?_c('span',{staticClass:"ml-1"},[_vm._v(" ("),_c('b',[_vm._v("+"+_vm._s(event.attendees.length)+" guest"+_vm._s(event.attendees.length > 1 ? "s" : "")+" ")]),_vm._v(") ")]):_vm._e()],1):_vm._e()]):_c('div',[_c('b',[_vm._v(_vm._s(event.name))])])])]}},{key:"day-body",fn:function(ref){
var date = ref.date;
var week = ref.week;
return [_c('div',{staticClass:"v-current-time",class:{ first: date === week[0].date },style:({ top: _vm.nowY })})]}}]),model:{value:(_vm.focus),callback:function ($$v) {_vm.focus=$$v},expression:"focus"}}),_c('RegisterRosterComponent',{ref:"dialogRegisterRoster",on:{"save-event-success":_vm.onRosterEventsChanged}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }